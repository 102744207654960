import { useLoggedIn } from 'bv-hooks';
import App from './components/app';
import LoggedOutGameLaunch from '../game_launch/logged_out_game_launch';

const FtpGameLauch = () => {
  const loggedIn = useLoggedIn();

  return loggedIn ? <App /> : <LoggedOutGameLaunch />;
};

export default FtpGameLauch;

import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';

import { fetchCTCProgress } from 'CasinoV2/api';
import CTCIcon from './ctc_icon';

const CTCIconContainer = ({ gameSessionId, gameVersionId }) => {
  const [response, loading] = useFetch(() => fetchCTCProgress({
    gameSessionId, gameVersionId, codeFilter: 'DAILY',
  }));

  if (loading || response.error || !response.data?.pushAddresses?.progress) return null;

  return <CTCIcon data={response.data} />;
};

CTCIconContainer.propTypes = {
  gameSessionId: PropTypes.string.isRequired,
  gameVersionId: PropTypes.number.isRequired,
};

export default CTCIconContainer;

import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { v as bvVar } from 'bv';
import { mainContextBack } from 'bv-helpers/location';
import { RenderInBody } from 'bv-components';
import Context from 'casino-context';

import PostGameSummary from 'CasinoV2/components/post_game_summary';
import Header from './header';

const Modal = ({
  children, ftp = false, fullscreen = false, gameSessionId, gameVersionId, tags,
}) => {
  const { hideHeader } = useContext(Context);
  const [postGame, setPostGame] = useState(false);
  const closeAction = Object.values(bvVar('crackTheCode')).includes(true)
    ? () => setPostGame(true)
    : mainContextBack;

  // TODO: DWT-7254
  // Temporary solution to hide chat bubble on desktop when a game has been launched.
  useEffect(() => {
    document.body.classList.add('hide-chat');
    // To prevent custom zoom scaling for casino game page on Windows machine
    // we need to add additional classname to html element.
    document.documentElement.classList.add('casino-game-launched');
    return () => {
      document.body.classList.remove('hide-chat');
      document.documentElement.classList.remove('casino-game-launched');
    };
  }, []);

  return (
    <RenderInBody className="gl-modal-container">
      <div className="gl-modal-wrapper">
        <div className={classnames('gl-modal',
          { 'gl-modal__no-header': hideHeader },
          { 'gl-modal--ftp': ftp })}
        >
          {!hideHeader && (
            <Header
              gameSessionId={gameSessionId}
              gameVersionId={gameVersionId}
              fullscreen={fullscreen}
              tags={tags}
              ftp={ftp}
              closeAction={closeAction}
            />
          )}

          <div className="gl-modal__body">
            {children}
          </div>
        </div>
        {postGame && (
          <PostGameSummary
            closeGame={mainContextBack}
            gameSessionId={gameSessionId}
            gameVersionId={gameVersionId}
          />
        )}
      </div>
    </RenderInBody>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  ftp: PropTypes.bool,
  gameSessionId: PropTypes.number,
  gameVersionId: PropTypes.number.isRequired,
  fullscreen: PropTypes.bool,
  tags: PropTypes.instanceOf(Array),
};

Modal.defaultProps = {
  gameSessionId: null,
  ftp: false,
  fullscreen: false,
  tags: [],
};

export default Modal;

// TODO: To check with Jason
// Forced to use RenderInBody because of this css:
// contents {
//  -webkit-transform: translate3d(0, 0, 0);
// }

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useEventBus } from 'bv-hooks';

const CTCIcon = ({ data: { casinoProgress, pushAddresses, competitionType } }) => {
  const [currentDaily, setCurrentDaily] = useState(casinoProgress?.currentDaily);
  const [badge, setBadge] = useState('');
  const maxDaily = casinoProgress?.maxDaily;

  const updateIcon = (err, newData) => {
    if (err) return;

    const newDaily = newData?.body?.casinoProgress?.currentDaily;

    if (newDaily !== undefined && newDaily > currentDaily) {
      if (!badge) {
        setBadge(newData?.body?.descriptions?.CTC_ZONE_CODE_EARNED || '');
        setTimeout(() => setBadge(''), 5000);
      }
      setCurrentDaily(newDaily);
    }
  };

  useEventBus(
    pushAddresses.progress,
    updateIcon,
    [maxDaily],
  );

  if ([currentDaily, maxDaily].includes(undefined)) return null;

  return (
    <div className="gl-ctc-icon__wrapper">
      <span className={`gl-ctc-icon gl-ctc-icon--${competitionType?.toLowerCase()}`}>
        {badge
          ? <span className="gl-ctc-icon__code-earned">{badge}</span>
          : <span className="gl-ctc-icon__codes">{`${currentDaily}/${maxDaily}`}</span>}
      </span>
    </div>
  );
};

CTCIcon.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default CTCIcon;

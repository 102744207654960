import { MessageBox } from 'bv-components';
import { t } from 'bv-i18n';

const LoggedOutMessage = () => (
  <MessageBox actions={[{ label: t('login'), to: '/login', primary: true }]} icon={false}>
    <p>{t('javascript.casino.login_required')}</p>
  </MessageBox>
);

export default LoggedOutMessage;

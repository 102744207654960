import { useParams } from 'react-router';
import { useFetch } from 'bv-hooks';
import { withScrollToTop } from 'bv-hocs';
import { Spinner } from 'bv-components';
import { fetchFTPGamesJson } from 'CasinoV2/api';

import ErrorModal from './error_modal';
import FtpGameModal from './ftp_game_modal';

const App = () => {
  const { gameId } = useParams();
  const [ftpGame, loading] = useFetch(() => fetchFTPGamesJson(gameId), []);

  if (loading) return <Spinner />;

  const { game_launch_url: url, errorCode } = ftpGame;

  return url
    ? <FtpGameModal url={url} />
    : <ErrorModal errorCode={errorCode} />;
};

export default withScrollToTop()(App);

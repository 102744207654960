import { useEffect, useState } from 'react';
import { humanTimeDistance } from 'bv-helpers/datetime';

const Timer = () => {
  const [time, setTime] = useState('00:00:00');
  const startTime = new Date();

  const format2Digits = (unit) => unit?.toString()?.padStart(2, '0') || '00';

  useEffect(() => {
    const interval = setInterval(() => {
      const { hours, minutes, seconds } = humanTimeDistance(startTime, { daysAsHours: true });

      setTime(`${format2Digits(hours)}:${format2Digits(minutes)}:${format2Digits(seconds)}`);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="gl-pl-timer__timer">
      {time}
    </div>
  );
};

export default Timer;

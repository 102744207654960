import { memo } from 'react';
import { Redirect } from 'react-router';
import { Robots } from 'bv-metas';
import LoggedOutMessage from './logged_out_message';

export default memo(() => (
  <>
    <Robots content="noindex, nofollow" />
    <Redirect to={{ pathname: '/login' }} />
    <LoggedOutMessage />
  </>
), () => true);

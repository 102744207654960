import { useEffect, useState } from 'react';
import { accountData } from 'bv';

export const useUKUser = () => {
  const [isCCUser, setCCUser] = useState(false);

  useEffect(() => {
    accountData().then((account) => {
      setCCUser(['gb', 'ca'].includes(account.country_code.toLowerCase()));
    });
  }, []);

  return isCCUser;
};

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { hasCasino } from 'global-config';
import { t } from 'bv-i18n';

const errorKey = (code, type) => `javascript.ftp_game_launch.error.${code}.${type}`;
const redirectToUrl = (url) => window.location.replace(url);
const onCloseClick = () => (document.referrer.includes(window.location.host)
  && !document.referrer.includes('/login')
  ? window.history.back()
  : redirectToUrl('/'));

const defaultActions = [{
  label: t('close'), danger: true, inverse: true, onClick: onCloseClick,
}];

const ErrorModal = ({ errorCode }) => {
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    switch (errorCode) {
      case 'FTPG-400':
      case 'FTPG-403':
        setErrorMessage({
          title: t(errorKey(errorCode, 'title')),
          message: t(errorKey(errorCode, 'message')),
          actions: defaultActions,
        });
        break;
      case 'FTPG-405':
        setErrorMessage({
          title: t(errorKey(errorCode, 'title')),
          message: t(errorKey(errorCode, 'message')),
          actions: [
            {
              label: t('ok'), danger: true, inverse: true, onClick: onCloseClick,
            },
            {
              label: t('javascript.ftp_game_launch.cta.see_offers'),
              danger: true,
              onClick: () => redirectToUrl(hasCasino ? '/offer/casino' : '/offer'),
            },
          ],
        });
        break;
      default:
        setErrorMessage({
          title: t(errorKey('default', 'title')),
          message: t(errorKey('default', 'message')),
          actions: defaultActions,
        });
    }
  }, [errorCode]);

  if (!errorMessage) return null;

  return (
    <RenderInBody>
      <Modal
        title={errorMessage.title}
        danger
        onCloseClick={onCloseClick}
        actions={errorMessage.actions}
      >
        <p>
          {errorMessage.message}
        </p>
      </Modal>
    </RenderInBody>
  );
};

export default ErrorModal;

ErrorModal.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import { isDesktop } from 'bv';
import { Robots } from 'bv-metas';
import { Modal } from '../../game_launch/launch_types/desktop/shared';

const FtpGameModal = ({ url }) => (
  <Modal ftp fullscreen={!isDesktop()}>
    <Robots content="noindex, nofollow" />
    <iframe src={url} className="ftp-iframe" frameBorder="0" />
  </Modal>
);

export default FtpGameModal;

FtpGameModal.propTypes = {
  url: PropTypes.string.isRequired,
};

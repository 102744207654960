import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { Spinner, Icon } from 'bv-components';
import { fetchCTCProgress } from 'CasinoV2/api';
import withLazyLoad from 'bv-lazy-load';

const CrackTheCodeProgress = withLazyLoad(<Spinner />)('crackTheCodeProgress');

const PostGameSummary = ({ closeGame, gameSessionId, gameVersionId }) => {
  const [response, loading] = useFetch(
    () => fetchCTCProgress({ gameSessionId, gameVersionId, codeFilter: 'SESSION' })
      .catch(() => { closeGame(); }),
    [gameSessionId, gameVersionId],
  );

  if (response?.error) closeGame();

  const competitionType = response?.data?.competitionType?.toLowerCase() || 'standard';

  return (
    <div className="gl-overlay bvs-overlay">
      {loading || !response || response?.error
        ? <Spinner />
        : (
          <div className="gl-modal bvs-modal">
            <CrackTheCodeProgress
              type={`game-launch-${competitionType}`}
              codes={response.data?.codes}
              progress={response.data?.casinoProgress}
              descriptions={response.data?.descriptions}
            />
            <span className="close-icon" onClick={closeGame}>
              <Icon
                id="close"
                type="gl-modal-close-icon"
                className="gl-modal__close-btn"
              />
            </span>
          </div>
        )}
    </div>
  );
};

PostGameSummary.propTypes = {
  closeGame: PropTypes.func.isRequired,
  gameSessionId: PropTypes.string.isRequired,
  gameVersionId: PropTypes.number.isRequired,
};

export default PostGameSummary;

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { registerHandler, unregisterHandler } from 'event-bus';
import { toCurrency } from 'bv-helpers/number';
import classnames from 'classnames';
import useCloseSlot from './use_close_slot';

const ProfitAndLoss = ({ gameSessionId }) => {
  const [profitAndLoss, setProfitAndLoss] = useCloseSlot(0);

  useEffect(() => {
    const handlePAL = (err, { body: { pal } }) => {
      setProfitAndLoss(pal);
    };
    const palAddress = `/sessionprofitandloss/${gameSessionId}`;

    registerHandler(palAddress, handlePAL);
    return () => { unregisterHandler(palAddress, handlePAL); };
  }, []);

  const className = classnames('gl-pl-timer__profit_and_loss ', {
    'is-negative': profitAndLoss < 0,
  });

  return (
    <div className={className}>
      {toCurrency(Math.abs(profitAndLoss))}
    </div>
  );
};

ProfitAndLoss.propTypes = {
  gameSessionId: PropTypes.number.isRequired,
};

export default ProfitAndLoss;

import { useEffect, useState } from 'react';

export default (initialState) => {
  const [profitAndLoss, setProfitAndLoss] = useState(initialState);

  useEffect(() => {
    const handleMessage = (e) => {
      const message = e.data;
      if (message.sender === 'bingo' && message.name === 'CLOSE_SLOT') {
        setProfitAndLoss(0);
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return [profitAndLoss, setProfitAndLoss];
};
